<template>
  <div class="settings" style=" ">
    <div class="content">
      <loader v-if="!pageLoaded"></loader>
      <div v-if="pageLoaded"  :style="{ 'min-height': windowHeight - 110 + 'px;' }">


        <div style="padding: 0; margin-left: 57px; margin-top:30px;">

          <router-link to="/panel/istekler" class="cuper-black-button" style="width: 113px; height: 46px;">
            Geri Dön
          </router-link>


          <div v-if="warning.displayWarning && warning.disapprovals.length" class="request-warning">
            <div class="request-warning-inside">
              <div>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-warning.svg"  onload="SVGInject(this)" class="warning-icon">
              </div>
              <div style="margin-left: 15px;">
                <p class="warning-head">Onaylanmadı</p>
                <p class="warning-sub-head">Aşağda belirtilen hataları gidererek, onay aşamasına gönderebilirsin.</p>

                <p class="disapproval-row" v-for="disapproval in warning.disapprovals">
                  <span class="warning-description-html">{{disapproval.title}}</span>
                  <span class="warning-description-html" v-if="disapproval.type === 'custom'" v-html="disapproval.description" style="font-weight: 300"></span>
                  <router-link class="link" v-if="disapproval.support_link_url" :to="disapproval.support_link_url">[ayrıntılı bilgi]</router-link>
                </p>

              </div>
            </div>
          </div>

          <div style="margin-top: 30px;">

            <div style="margin-bottom: 0px;">
              <div style="display: inline-block">
                <label>Kategori Seçimi</label>
                <select v-model="selectedCategory" class="super-drop-c"  :style="selectedCategory ? 'border: solid 1px #2d3640;color:#2d3640' : ''">
                  <option disabled :value="null">Kategori Seç</option>
                  <option v-for="category in categories" v-if="!category.parent_id" :value="category">{{ category.name }}</option>
                </select>
              </div>

              <div style="display: inline-block; margin-left: 30px;">
                <label></label>
                <select v-model="selectedSubCategory" :disabled="!selectedCategory.id" class="super-drop-c" :style="selectedSubCategory && selectedSubCategory.id ? 'border: solid 1px #2d3640;color:#2d3640' : ''">
                  <option disabled :value="undefined">Kategori Seç</option>
                  <option  v-for="(subCategory, index) in categories"  :key="index+300" v-if="selectedCategory && (selectedCategory.id === subCategory.parent_id)" :value="subCategory">
                    {{ subCategory.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- service type eğer varsa -->
            <div v-if="selectedSubCategory && selectedSubCategory.id && selectedSubCategory.category_service_types &&  selectedSubCategory.category_service_types.length > 0 " class="application-sections">
              <div class="application-sections-flex1">
                <label>Hizmet Türü</label>
              </div>
              <div class="application-sections-flex2" >
                <div class="categories-container">
                  <select class="super-drop-c"
                          v-model="selectedServiceType"
                          :style="selectedServiceType ? 'border: solid 1px #2d3640;color:#2d3640' : ''"
                  >
                    <option :value="null">Seç...</option>
                    <option v-for="option in selectedSubCategory.category_service_types" :value="option.id" @onchange="">{{option.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="showMetaOptions"  class="sub-cat-type-kind-container">
              <p class="title">Temel Bilgiler</p>
              <div style="display: flex; align-items: flex-start">
                <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                <p class="help-text" >İyi teklifler alabilmek için alttaki bilgileri dikkatle doldurmalısın.</p>
              </div>


              <div class="meta-options-box">
                <div class="meta-options-right">
                  <div v-if="reRender && !meta_option.dontShow && selectedSubCategory && computedMetaOptions" @click="selectMetaOptionBox(mindex)" v-for="(meta_option, mindex) in computedMetaOptions"  :key="mindex" :class="meta_option.selected ? 'meta-options-right-row-selected' : 'meta-options-right-row' " >
                    <div class="meta-options-right-row-text" :style="isMetaOptionsSelected(mindex) ? 'color:#5e6b79' : 'color: #fe5b5c;'">
                      <p>{{ meta_option.title_for_buyer }} {{isMetaOptionsSelected(mindex) ? '' : '*'}}</p>
                      <div class="check-icon-container" v-show="isMetaOptionsSelected(mindex)">
                        <img class="check-icon"
                             src="https://gcdn.bionluk.com/site/cicons/ic-check.svg"
                             onload="SVGInject(this)"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="reRender && selectedMetaOption" class="meta-options-left">

                  <p class="option-left-title">{{selectedMetaOption.description_for_buyer}} <span style="font-weight: 500">{{selectedMetaOption && selectedMetaOption.info && selectedMetaOption.info.max ? '(en fazla '+selectedMetaOption.info.max +' seçenek)' : ''}}</span></p>
                  <div v-if="selectedMetaOption && selectedMetaOption.info" class="meta-options-left-form">
                    <template v-if="selectedMetaOption.info.type === 'radio'">
                      <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options" class="filter-radio-item">
                        <div :class="option.selected ? 'filter-radio-full' : 'filter-radio-empty'"></div>
                        <p :class="option.selected ? 'filter-radio-label-active' : 'filter-radio-label' ">{{option.title_for_buyer}}</p>
                      </div>
                    </template>
                    <template v-if="selectedMetaOption.info.type === 'checkbox'">
                      <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options"  class="filter-checkbox-item" style="display: flex" >
                        <custom-check
                          :container-style="'width:20px; height:20px;'"
                          :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                          called-from=""
                          :single-gig="{}"
                          :id="option.id + '__' + option.title"
                          :checked="option.selected"
                          :disabled="!option.selected && isMetaOptionsDisabled()"
                          :value="option.selected"
                          @input="selectMetaOption(oindex)"
                        />
                        <div :class="option.selected ? 'filter-checkbox-label-active' : isMetaOptionsDisabled() ? 'filter-checkbox-label-disabled' : 'filter-checkbox-label'">{{option.title_for_buyer}}</div>
                      </div>
                    </template>
                    <template v-if="selectedMetaOption.info.type === 'selectbox'">
                      <div style="margin-top: 30px">
                        <select id="sel" class="super-drop" v-model="dummyMetaOption">
                          <option :value="null" disabled style="color: #8b95a1">Seç</option>
                          <option :value="option.id" v-for="(option, oindex) in selectedMetaOption.options">{{option.title_for_buyer}}</option>
                        </select>
                      </div>
                    </template>
                  </div>



                </div>
              </div>


            </div>

            <template v-if="selectedSubCategory && selectedSubCategory.id && (selectedSubCategory.id === 38 || selectedSubCategory.id === 61)">
              <label style=" margin-top: 70px;">Kelime Sayısı</label>
              <div style="display: flex; justify-content: space-between;">
                <input @focusout="handleFocusOutWord"  maxlength="8" :style="wordCount ? 'border: solid 1px #00a575; width:355px;' : 'width:355px;'" v-model="wordCount" class="cuper-input" placeholder="Toplam kelime sayısını gir">
                <div style="width: 380px;">
                  <div style="display: flex; align-items: flex-start">
                    <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                    <div style="margin-left:5px;color: #4b4f52; line-height: 1.71;font-weight: 300;font-size: 14px;">
                      <p v-if="selectedSubCategory.id === 38">Çevirisini yaptırmak istediğin metnin kaç kelimeden oluştuğunu bilmiyorsan buradan yararlanabilirsin.</p>
                      <p v-if="selectedSubCategory.id === 61">Seslendirilmesini istediğin metnin kaç kelimeden oluştuğunu bilmiyorsan buradan yararlanabilirsin.</p>
                      <a target="_blank" href="https://charcount.com" rel="nofollow" style="color: #00a575;font-weight: 500; margin-top: 5px;">https://charcount.com</a>

                    </div>
                  </div>
                </div>
              </div>
            </template>


            <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Tahmini bütçen</p>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 30px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              Proje için ayırdığın bütçeyi tahmini, <span style="font-weight: 600; color: #2d3740">fakat gerçekçi</span> olarak belirtmen freelancerların ilgisini çekmek için önemli.
            </p>
            <input @focusout="handleFocusOut" @focusin="handleFocusIn" maxlength="5" :style="budgetText ? 'border: solid 1px #00a575;' : ''" v-model="budgetText" class="cuper-input" placeholder="Tahmini bütçeni gir (₺)">
            <p style="margin-top: 60px; margin-bottom: 20px; font-weight: 500; color: #2d3640; font-size: 26px">Teslim süresi</p>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 10px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              Proje için belirleyeceğin süreyi <span style="font-weight: 600; color: #2d3740">gerçekçi ve olası gecikmeleri</span> de düşünerek vermen önemli.
            </p>
            <div class="sub-cat-box-container" style="margin-top: 0px;">
              <div @click="selectDuration(request_duration)" :class="duration.toString() === request_duration.id ? 'sub-cat-box-active' : 'sub-cat-box'"  v-for="(request_duration, index) in $store.state.request_durations">
                <span>{{request_duration.name}}</span>
              </div>
            </div>




            <label style="margin-top: 70px;">Yaptırmak istediğin işin detayları</label>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              İhtiyacın olan işle ilgili detayları ve freelancer’ların bilmesi gerekenleri <span style="font-weight: 600; color: #2d3740">sade ve anlaşılır biçimde</span> iletmen önemli.
            </p>

            <input :style="title ? 'border: solid 1px #00a575;width:843px;' : 'width:843px;'" v-model="title" class="cuper-input" placeholder="Alıcı isteğine etkileyici bir başlık gir…">
            <textarea v-model="description" style="margin-top: 20px; width: 843px; height: 200px;" class="c-text-area" placeholder="Örnek Metin:
5.000 Kelimeden oluşan bir tıp makalesinin Türkçe’den, İngilizce’ye çevrilmesine ihtiyacım var. 2 hafta içerisinde teslim edilmesi gerekmekle birlikte daha önce tıp çevirisi yapmış arkadaşların teklif vermesini tercih ederim. Makalenin 1 paragraflık kısmına eklerden ulaşabilirsin."></textarea>


            <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Örnek dosyalar</p>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              İşle ilgili varsa, <span style="font-weight: 600; color: #2d3740">örnek görsel, metin, ses veya video</span> eklemen freelancerların işi daha rahat anlamalarını sağlar.
            </p>


            <div class="upload-area-container">
              <div v-if="!dragAndDropArea" class="upload-area" >
                <p class="upload-title">Sürükle & Bırak</p>
                <p class="upload-sub-title">Bu alana eklemek istediğin dosyaları sürükleyip bırakabilirsin.</p>
                <p class="upload-or">YA DA</p>
                <div style="margin: 20px auto 0;width: 220px;">
                  <button @click="chooseFiles('request_upload')" style="width: 220px; height: 54px;" class="cuper-black-button">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
                    <span style="padding-left: 14px;">Dosya Ekle</span>
                  </button>
                </div>
                <p class="upload-foot-title">Ekleyebileceğin dosya tipleri:</p>
                <p class="upload-foot-sub-title">.DOC, .XLS .TXT, JPG, PNG, .GIF, PDF, .MP4, .MP3, .MOV, .MPEG, .Sketch, .XD, .PSD</p>
              </div>
              <div  v-else
                    id="request-drag-drop"
                    draggable="true"
                    @drop="dropHandler($event)"
                    @dragover="dragOverHandler($event)"
              >
                {{dragDropText}}
              </div>

              <div class="upload-area-files">
                <div v-if="requestFiles.length" style="display: flex; justify-content: space-between">
                  <p class="upload-files-title">YÜKLENEN</p>
                  <p class="upload-file-count">{{requestFiles.length}} dosya</p>
                </div>

                <div v-for="file in requestFiles" style="margin-top: 45px;">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div style="display: flex; align-items: center">
                      <div v-if="file.url"> <img src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)" class="check-icon"> </div>
                      <img v-if="file.icon_png" style="height: 41px; width: 32px; padding-left: 22px; " :src="file.icon_png">
                      <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 41px; width: 32px; padding-left: 22px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                      <div style="display: flex; flex-direction: column; margin-left: 15px;">
                        <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 16px; text-overflow: ellipsis; max-width: 240px; overflow: hidden; white-space: pre">{{ file.filename }}</a>
                        <progress v-if="$store.state.percentCompleted.request[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.request[file.stamp]' max="100"></progress>
                        <p style="color:#00a575;font-size: 14px; font-weight: 500;margin-top: 5px;">%{{parseInt($store.state.percentCompleted.request[file.stamp]) || 100}} Tamamlandı <span v-if="file.filesize" style="color: #8b95a1;">· {{formatBytes(file.filesize)}}.</span></p>
                      </div>
                    </div>


                    <div v-show="!file.url" @click="cancelUpload(file.stamp)" class="icon-area">
                      <img  src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon">
                    </div>
                    <div v-show="file.url" @click="deleteFile(file.externalID)" class="icon-area">
                      <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img">
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <input id="request_upload" style="visibility: hidden" type="file" @change="onFileChange($event)" multiple>

          </div>



          <div v-if="1===2 && !hide_is_plus_area" style="margin-top: 30px;  ">

            <p style=" margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Alıcı isteği kimlere gösterilsin</p>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              Her iki seçenek için de bu aşamada herhangi bir ödeme yapmayacak olduğunu hatırlatırız.
            </p>

            <div style="display: flex; justify-content: space-between; ">
              <div @click="switchPlus(false)" class="plus-container" :class="!is_plus ? 'active' : ''">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/ilSearch%402x.png">
                </div>
                <div style="margin-left: 20px; margin-top: 25px; width: 230px;">
                  <p class="title">Tüm freelancerlar görsün</p>
                  <p class="text">Alıcı isteğin tüm freelancer’lara gösterilir. Gelen teklifler arasında ihtiyacına en uygun freelancer’ı kendin belirler ve anlaşmaya varırsanız siparişi başlatırsın.</p>
                </div>
              </div>
              <div @click="switchPlus(true)" class="plus-container" :class="is_plus ? 'active' : ''">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/ilBestFreelancers%402x.png">
                </div>
                <div style="margin-left: 20px; margin-top: 25px; width: 230px;">
                  <p class="title">Bionluk Plus’ı dene</p>
                  <p class="text">Alıcı isteğin sadece Bionluk’un tavsiye ettiği ve güvendiği freelancer’lara gösterilir ve sipariş süreci boyunca karşılacağın tüm olası problemlerde sana özel <span>Bionluk Müşteri Temsilcin</span> destek için hazır olur.</p>

                </div>

              </div>
            </div>

            <div v-show="is_plus" class="u-plus-box">
              <div class="left-b">
                <img style="width: 155px;" src="https://gcdn.bionluk.com/site/cicons/ilplus.png"/>

                <div style="margin-top: 20px; width: 100%; ">
                  <p class="price">29.00<span style="padding-left: 3px">₺</span></p>
                  <p style="font-size: 38px; color:#2D3640; margin-top: 15px; font-weight: bold; letter-spacing: -0.5px;">Ücretsiz</p>
                </div>

                <div style="display: flex; align-items: flex-start; width: 185px; text-align: left; margin: 30px auto;">
                  <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <div style="margin-left:5px;  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #5e6b79;">
                    Bu adımda <span style="font-weight: bold;color: #2d3640;">herhangi bir ücret ödemeyeceksin!</span> Hizmet bedeli sipariş onay aşamasında tahsil edilir.
                  </div>
                </div>
              </div>
              <div class="right-b">
                <p style="display:flex; justify-content: center; align-items: center; height: 16px; padding-bottom: 2px;  width: 40px; color: #fff; text-align: center;  border-radius: 10px;
  background-color: #26ca9a;  letter-spacing: -0.22px; font-size: 11px;">Yeni</p>
                <p class="title">Bionluk <span>Plus</span></p>
                <p class="sub-title">
                  <span>Sadece 500 TL ve üzeri</span> alıcı isteği oluşturan üyelerimize özel olarak verilen ayrılacılıklı bir hizmet çeşididir.
                </p>

                <div class="li-container">
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Alıcı isteğin Bionluk ekibi tarafından incelenir, <span>fiyat ve süre analizi</span> yapılır.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Alıcı isteğin sadece alanında uzman ve en iyi freelancerlara gösterilir.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">İlanına teklifler gelmeye başladığı anda <span>e-posta ile bilgilendirilirsin.</span></p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Gelen teklifleri <span>fiyat ve zaman</span> olarak sıralayabilirsin.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Siparişin süresince, <span>Bionluk Müşteri Temsilcin</span> destek için yanında olacak.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Freelancer ile yaşanılacak olası anlaşmazlık ve sipariş iptal durumunda <span>Bionluk Plus hizmet bedeli iadesi garanti!</span></p>
                  </div>
                </div>

              </div>
            </div>
          </div>



          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>


        </div>

      </div>
    </div>
    <div class="bottom-fixed-div">
      <div class="bottom-centered">
        <div style="display: flex">
          <div style="width: 300px;">
            <p class="step-title"></p>
            <p class="step-sub-title">Alıcı İsteğini Düzenle</p>
          </div>
          <div v-if="selectedCategory.id" style="display: flex; height: 46px; margin-right:20px; align-items: center">
            <div class="bottom-cat-img-container" :style="'background:'+selectedCategory.cat_color">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCategory.id+'.png'">
            </div>
            <p class="bottom-cat-title">{{selectedCategory.name}}</p>
          </div>
          <div v-if="selectedSubCategory && selectedSubCategory.name && selectedSubCategory.id" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ selectedSubCategory.name }}
          </div>
          <div v-if="selectedServiceKind && selectedSubCategory.category_service_kinds" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ selectedSubCategory.category_service_kinds.options.find(({ id }) => id === selectedServiceKind)['name'] }}
          </div>
          <div v-if="selectedServiceType && selectedSubCategory.category_service_types" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ selectedSubCategory.category_service_types.find(({ id }) => id === selectedServiceType)['name']}}
          </div>
          <div v-if="wordCount" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{wordCount}} kelime
          </div>
          <div v-if="budget" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{budget}}₺
          </div>
          <div v-if="duration" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ $store.state.request_durations.find(({ id }) => id === String(duration))['name']}}
          </div>
        </div>
        <div @click="editRequest" :class="enableButton ? 'continue-button-active' : 'continue-button'">
          Güncelle
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dropdown from 'vue-my-dropdown';
  import Multiselect from 'vue-multiselect'
  import CustomCheck from "@/pages/components/CustomCheck";

  export default {
    name: "src-pages-body-workstation-requests-edit-v3",
    components: {
      dropdown,
      Multiselect,
      CustomCheck
    },
    data() {
      return {
        buttonLoading:false,
        pageLoaded: false,
        position: ["right", "top", "right", "top"],
        animation: 'ani-slide-y',
        selectedCategory: {},
        selectedSubCategory: {},
        selectedServiceKind:null,
        selectedServiceType:null,
        wordCount:null,
        budget:0,
        budgetText:'',
        duration:null,
        requestFiles:[],

        description: null,
        title: null,
        requestDuration: null,
        requestBudget: null,
        request_uuid: null,
        warning: null,
        status: 1,

        is_plus:false,
        hide_is_plus_area:false,

        dragAndDropArea: false,
        dragDropText: 'Dosyaları bu alana sürükle.',

        selectedMetaOption:{},
        dummyMetaOption:null,
        reRender:true

      }
    },

    methods: {

      selectMetaOption(i=null){
        let selectedMetaOption = this.selectedMetaOption;
        const foundIndex = this.selectedSubCategory.meta_options.findIndex(option => option.selected)
        let currentOptions = this.selectedSubCategory.meta_options[foundIndex].options;

        let count = 0;
        currentOptions.forEach((co,coi) => {
          if(co.selected){
            count++;
          }
        });

        currentOptions.forEach((o,oi) => {
          if(selectedMetaOption?.info.type === 'radio'){
            if(i === oi){
              o.selected = !o.selected
            } else {
              o.selected = false;
            }
          } else if(selectedMetaOption?.info.type === 'checkbox'){
            if(i === oi){
              if(selectedMetaOption?.info?.max){
                if(!o.selected){
                  if(selectedMetaOption.info.max > count){
                    o.selected = true;
                  }
                } else {
                  o.selected = false;
                }
              } else {
                o.selected = !o.selected;
              }
            }
          } else if(selectedMetaOption?.info.type === 'selectbox'){
            if(o.id === i){
              o.selected = true;
            } else {
              o.selected = false;
            }
          }
        });
        this.reRender = false;
        this.reRender = true;
      },

      isMetaOptionsSelected(i){
        let isSelected = false;
        if(this.selectedSubCategory && this.selectedSubCategory.meta_options){
          this.selectedSubCategory.meta_options.forEach((o,oi) => {
            o.options.forEach( (oo,ooi) => {
              if(i === oi && oo.selected){
                isSelected = true
              }
            })
          });
        }
        return isSelected;
      },

      isMetaOptionsDisabled(){
        let isDisabled = false;
        const foundIndex = this.selectedSubCategory.meta_options.findIndex(option => option.selected)
        if(foundIndex > -1 && this.selectedSubCategory.meta_options[foundIndex]?.info?.max){
          let currentOptions = this.selectedSubCategory.meta_options[foundIndex].options;
          if(currentOptions){
            let count = 0;
            currentOptions.forEach( (oo,ooi) => {
              if(oo.selected){
                count++;
              }
            })
            if(this.selectedSubCategory.meta_options[foundIndex].info.max <= count){
              isDisabled = true;
            }
          }
        }
        return isDisabled;
      },

      selectMetaOptionBox(index){
        this.selectedSubCategory.meta_options.forEach((m,i) => {
          if(index === i){
            m.selected = true;
            this.selectedMetaOption = m;
          } else {
            m.selected = false;
          }

        });

        this.reRender = false;
        this.reRender = true;
      },

      switchPlus(plus){
        if(plus){
          if(this.budget && this.budget > 499){
            this.is_plus = plus;
          } else {
            this.$toasted.global.errorToast({description: 'Bionluk Plus servisimiz sadece 500TL ve üzeri alıcı isteklerinde geçerlidir.'});
          }
        } else {
          this.is_plus = plus;
        }

        this.is_plus = false;

      },

      formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },

      selectDuration(duration){
        this.duration = duration.id;
      },
      handleFocusOutWord(){
        if(isNaN(this.wordCount)){
          this.wordCount = null;
        } else {
          this.wordCount = this.wordCount ? this.wordCount.replace(".", "") : '';
        }
      },

      handleFocusOut(){
        if(isNaN(this.budgetText)){
          this.budgetText = '';
          this.budget = null;
        } else {
          this.budget = this.budgetText;
          this.budgetText = this.budgetText.replace(".", "");
          if(this.budget < 500){
            this.is_plus = false;
          }
          if(this.budget < 100){
            this.budgetText = '';
            this.budget = null;
            this.$toasted.global.errorToast({description: 'Tahmini bütçen minimum 100 TL olmalı.'});
          } else {
            this.budgetText += ' ₺';
          }
        }
      },
      handleFocusIn(){
        this.budgetText = this.budgetText.replace(' ₺', '');
      },


      getJobDetail(slug) {
        this.api.request.get(slug)
          .then(async ({data}) => {
            let result = data;

            if (result.success) {

              this.selectedCategory = await this.findCategoryByID(result.data.category.id)
              this.description = result.data.description_textarea;
              this.title = result.data.title;
              this.requestDuration = result.data.request_duration_id;
              this.requestBudget = result.data.request_budget_id;
              this.request_uuid = result.data.request_uuid;
              this.status = result.data.status;
              result.data.uploads.forEach(u => {
                u.externalID = u.id;
              });
              this.requestFiles = result.data.uploads;
              this.selectedSubCategory = await this.findCategoryByID(result.data.category_sub.id)
              if(this.selectedSubCategory && this.selectedSubCategory.id){
                if(result.data.request_service_types && result.data.request_service_types[0]){
                  this.selectedServiceType = result.data.request_service_types[0].id;
                }
              }

              let subCat = this.selectedSubCategory;

                let isSelectedMetaOption = false;
                subCat.meta_options.forEach((o,oi) => {
                  if(!isSelectedMetaOption){
                    if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                      o.selected = false;
                    } else {
                      this.selectedMetaOption = o;
                      o.selected = true;
                      isSelectedMetaOption = true;
                    }
                  } else {
                    o.selected = false;
                  }

                o.options.forEach( (oo,ooi) => {
                  let sel = false;
                  result.data.request_meta_options.forEach((go, goi) => {
                    if(o.id === go.id){
                      go.options.forEach((goo, gooi) => {
                        if(goo === oo.id){
                          sel = true;

                          if(o.info?.type === 'selectbox'){
                            this.dummyMetaOption = oo.id;
                          }
                        }
                      })
                    }
                  })

                  oo.selected = sel;
                })
              });


              this.selectedSubCategory = subCat;
              this.rerender = false;
              this.rerender = true;

              this.wordCount = result.data.wordCount;
              this.budget = result.data.budget;
              this.budgetText = this.budget+' ₺';
              this.duration = result.data.request_duration_id;
             // this.is_plus = result.data.is_plus;
              this.is_plus = false;


              this.warning = result.data.warning;

              this.rerender = false;
              this.rerender = true;

              this.$nextTick(() => {
                this.pageLoaded = true;
              });


            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
            console.log(err);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      editRequest() {
        if(this.buttonLoading){
          return false;
        }
        if(!this.enableButton){
          return false;
        }
        this.buttonLoading = true;


        let requestFiles = "";
        if (this.requestFiles.length > 0) {
          this.requestFiles.forEach(function (uploadResult, index) {
            requestFiles = requestFiles + (index === 0 ? "" : ",") + uploadResult.externalID;
          });
        }

        let status = 1;
        let wordCount = this.selectedSubCategory && this.selectedSubCategory.id && (this.selectedSubCategory.id === 38 || this.selectedSubCategory.id === 61) ? this.wordCount : null;

        let selectedMetaOptions = [];
        this.selectedSubCategory.meta_options.forEach(o => {
          let selectedOptions = [];
          let hasIt = false;
          o.options.forEach(oo => {
            if(oo.selected){
              hasIt = true;
              selectedOptions.push(oo.id)
            }
          })
          if(hasIt){
            selectedMetaOptions.push({
              id:o.id,
              options:selectedOptions
            })
          }
        });

        this.api.request.edit(this.request_uuid, this.title, this.description, this.selectedCategory.id, this.selectedSubCategory.id, null, this.duration, this.selectedServiceType, this.selectedServiceKind, status, null, requestFiles, this.budget, wordCount, this.is_plus ? '1' : '-1', JSON.stringify(selectedMetaOptions))
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              this.$router.push(result.data.redirect_url);
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.buttonLoading = false;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },





      chooseFiles(id) {
        document.getElementById(id).click()
      },

      cancelUpload(timeStamp){
        this.cancelUploadRequest(parseInt(timeStamp));
        let requestFileIndex = this.requestFiles.findIndex(f => f.stamp === timeStamp);
        if(requestFileIndex !== -1){
          this.requestFiles.splice(requestFileIndex, 1);
        }
      },
      deleteFile(uploadID) {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.requestFiles = this.requestFiles.filter(function (file) {
                return file.externalID !== uploadID
              });

              this.$toasted.global.infoToast({description: 'Silindi'});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      onFileChange(e) {

        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;


        let timeStamp = +new Date();

        for (let i = 0; i < files.length; i++) {

          let rand = Math.floor(Math.random() * 1000) + 1;
          timeStamp = timeStamp + rand + i;
          this.requestFiles.push({
            url: null,
            externalID: timeStamp,
            filename: files[i].name,
            stamp: timeStamp,
            filesize:files[i].size
          });

          this.uploadFileToGoogle(files[i], this.Constants.UPLOAD_TYPES.REQUEST,null, timeStamp)
            .then(result => {

              let requestFileIndex = this.requestFiles.findIndex(f => f.filename === files[i].name);
              let requestFile = this.requestFiles[requestFileIndex];
              requestFile.externalID = result.externalID;
              requestFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName;
              requestFile.icon_png = result.icon_png;
              this.requestFiles[requestFileIndex] = requestFile;



            }).catch(err => {
            console.log(err);
            this.$store.state.percentCompleted.request[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

          });
        }

      },

      deleteUploadedFile(file) {
        this.requestFiles = this.requestFiles.filter(({ externalID }) => externalID !== file.externalID)
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        let dragDrop = document.getElementById('request-drag-drop');
        if (event.target.id === "request-drag-drop") {
          this.dragDropText = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        let dragDrop = document.getElementById('request-drag-drop');
        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "request-drag-drop") {
          this.dragDropText = 'Dosyaları bu alana sürükle.';
        }
      },

      dropHandler(event) {
        this.EventBus.$emit('dragAndDropFile', null);
        event.preventDefault();
        event.stopPropagation();

        this.onFileChange(event);
        let dragDrop = document.getElementById('request-drag-drop');
        this.dragAndDropArea = false;


      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },

    },


    computed: {
      computedMetaOptions: function (){
        if(this.selectedSubCategory?.meta_options?.length > 0){
          if(this.selectedSubCategory?.category_service_types?.length > 0 && this.selectedServiceType ){
            this.selectedSubCategory.meta_options.forEach((mm,ii) =>{
              mm.dontShow = false
              if(mm.info.excluded_service_types?.length && mm.info.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                mm.dontShow = true
                mm.selected = false;
              }
            })
          }
          this.rerender = false;
          this.rerender = true;
          return this.selectedSubCategory.meta_options
        } else {
          return [];
        }
      },
      showMetaOptions(){
        let ret = (this.selectedSubCategory && this.computedMetaOptions && this.computedMetaOptions.length)
          && (this.selectedServiceType || (this.selectedSubCategory.category_service_types.length === 0 ) )
        return ret;
      },

      showStep2TypeArea(){

        if(this.selectedSubCategory && this.selectedSubCategory.id &&
          (this.selectedSubCategory.category_service_types && this.selectedSubCategory.category_service_types.length > 0)
          ||
          (this.selectedSubCategory && this.selectedSubCategory.meta_options && this.selectedSubCategory.meta_options.length > 0)
        )
        {
          return true;
        } else {
          return false
        }
      },
      enableButton: function(){

        if(!this.selectedCategory || !this.selectedCategory.id){
          return false
        }

        if(this.selectedSubCategory && this.selectedSubCategory.id){

          if(this.selectedSubCategory.id === 38 && !this.wordCount){
            return false
          }
          if(this.selectedSubCategory.id === 61 && !this.wordCount){
            return false
          }

          if(this.selectedSubCategory.category_service_types.length > 0){
            if(!this.selectedServiceType){
              return false;
            }
          }
        } else {
          return false
        }





        if(!this.duration){
          return false
        }
        if(!this.budget){
          return false
        }

        if(!this.title || !this.description){
          return false
        }

        return true;
      },
    },
    watch: {

      selectedServiceType: function (newVal, oldVal) {
        if(newVal){
          this.openPricingBox = true;
          let isSelectedMetaOption = false;
          this.selectedSubCategory.meta_options.forEach((o,oi) => {
            if(!isSelectedMetaOption){
              if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                o.selected = false;
              } else {
                this.selectedMetaOption = o;
                o.selected = true;
                isSelectedMetaOption = true;
              }
            } else {
              o.selected = false;
            }

            o.options.forEach( (oo,ooi) => {
              oo.selected = false;
            })
          });

          this.rerender = false;
          this.rerender = true;
        } else {
          this.rerender = false;
          this.rerender = true;
        }
      },
      selectedCategory(newVal, oldVal) {
        if (newVal && oldVal && oldVal.id) {
          this.selectedSubCategory = {id:null};
          this.selectedServiceType = null;
          this.selectedServiceKind = null;
          this.selectedMetaOption = null;
          this.dummyMetaOption = null;
          if(newVal.id === 6){
            this.hide_is_plus_area = true;
            this.is_plus = false;
          }
        }
      },

      selectedSubCategory(newVal, oldVal) {
        if (newVal && oldVal && oldVal.id) {
          this.selectedServiceType = null;
          this.selectedServiceKind = null;
          this.selectedMetaOption = null;
          this.dummyMetaOption = null;

        }


        if(newVal && this.pageLoaded){
          let subCat = newVal;

            if(subCat && ((subCat.category_service_types && subCat.category_service_types.length > 0) || (subCat.meta_options && subCat.meta_options.length > 0))){

              if(subCat && subCat.meta_options && subCat.meta_options && subCat.meta_options.length > 0 && subCat){

                subCat.meta_options.forEach((c,i) => {
                  c.options.forEach((m) => {
                    m.selected = false;
                  })
                  if(i===0){
                    this.selectedMetaOption = c;
                    c.selected = true;
                  } else {
                    c.selected = false;
                  }
                });

                this.selectedSubCategory = subCat;
                this.reRender = false;
                this.reRender = true;
              }

            }

        }
      },
      dummyMetaOption: function (newVal){
        let selectedMetaOption = this.selectedMetaOption;
        if(newVal){
          if(selectedMetaOption?.info?.type === 'selectbox'){
            this.selectMetaOption(newVal)
          }
        }
      },
    },

    created() {


      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$on('dragAndDropFile', droppedFile => {
        this.dragAndDropArea = false;
      });
      this.getJobDetail(this.$store.state.routerParams[0]);

    },

    beforeDestroy() {
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
    }
  }
</script>

<style scoped lang="scss">


.u-plus-box {
  display: flex;
  margin-top: 30px;
  width: 890px;
  height: 540px;

  border-radius: 10px;
  box-shadow: 0 20px 20px 0 #e9ebf0;
  border: solid 1px #eff3f8;
  background-color: #ffffff;

  .left-b{
    margin-top: 30px;
    margin-left: 30px;
    width: 382px;
    text-align: center;

    .price{
      text-decoration: line-through;
      font-size: 42px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      color: #8b95a1;
      span{
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: #8b95a1;
      }
    }
  }
  .right-b{
    margin-left: 30px;
    margin-top: 30px;

    .title{
      font-size: 46px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fd4056;
      span{
        font-weight: bold;
      }
    }

    .sub-title{
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.3px;
      color: #5e6b79;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }

    .li-container{
      margin-top: 25px;
      .li-row{
        display: flex;
        min-height: 20px;
        margin-top: 30px;
        align-items: center;
        .check-icon-container{
          .check-icon /deep/ {
            width: 20px;
            height: 20px;
            path{
              fill: #00a575;
            }
          }
        }

        .li{
          margin-left: 10px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #5e6b79;
          span{
            font-weight: bold;
            color: #2d3640;
          }
        }
      }
    }
  }
}
.plus-container{
  width: 410px;
  height: 227px;
  border-radius: 10px;
  border: solid 1px #bfc8d2;
  background-color: #ffffff;
  display: flex;
  cursor: pointer;

  .text{
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.26px;
    color: #5e6b79;
    span{
      font-weight: 600;
    }
  }

  &:hover{
    border: solid 1px #00a575;
  }
  &.active{
    border: solid 1px #00a575;
    background-color: rgba(103, 204, 100, 0.1);
    .text{
      color: #2D3640;
    }
  }
  img{
    width: 110px;
    margin-top: 50px;
    margin-left: 25px;
  }
  .title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #2d3640;
  }

}

  .icon-area{

    cursor: pointer;

    .delete-uploaded-file-img /deep/ {
      width: 14px;
      height: 14px;

      path {
        fill: #8b95a1;
      }
    }


    .delete-icon /deep/ {
      cursor: pointer;
      width: 11px;
      height: 14px;
      path {
        fill: #8b95a1;
      }

    }
    &:hover{

      .delete-uploaded-file-img /deep/ {
        width: 14px;
        height: 14px;

        path {
          fill: #2d3640;
        }
      }


      .delete-icon /deep/ {
        cursor: pointer;
        width: 11px;
        height: 14px;
        path {
          fill: #2d3640;
        }

      }
    }
  }


  .check-icon /deep/ {
    width: 20px;
    height: 15px;
    path {
      fill: #00a575;
    }
  }



  /*upload and drag/drop*/
  #request-drag-drop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 20px;
    color: rgb(230, 50, 98);
    font-weight: 500;


    margin-top: 30px;
    text-align: center;
    width: 393px;
    height: 380px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

  }

  .upload-plus-icon /deep/ {
    width: 18px;
    height: 18px;
    path {
      fill: #fff;
      &:hover{
        opacity: 0.8;
      }
    }


  }


  .upload-area-container{
    margin-top: 33px;
    display: flex;
    justify-content: space-between;
  }

  .upload-area-files{
    width: 393px;
    margin-left: 57px;

    .upload-files-title{
      font-size: 18px;
      font-weight: 600;
      color: #5e6b79;
    }
    .upload-file-count{
      font-size: 16px;
      font-weight: 500;
      color: #5e6b79;
    }

  }
  .upload-area{

    text-align: center;
    width: 393px;
    height: 380px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .upload-title{
      margin-top: 50px;
      font-size: 26px;
      font-weight: 600;
      line-height: 1.08;
      color: #5e6b79;
    }
    .upload-sub-title{
      margin-top: 10px;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: -0.23px;
      color: #5e6b79;
    }
    .upload-or{
      margin-top: 40px;
      font-size: 14px;
      color: #8b95a1;
    }
    .upload-foot-title{
      margin-top: 64px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;
    }
    .upload-foot-sub-title{

      max-width: 85%;
      margin: 10px auto 0;
      font-size: 12px;
      font-weight: 300;
      color: #8b95a1;
    }
  }



  .sub-cat-box-container{
    display: flex;
    flex-wrap: wrap;
    width: 843px;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }

  .bottom-fixed-div {
    position:fixed; bottom:0; width: 100%; background: linear-gradient(to right, #0e0e0f, #2d3740); height: 100px; z-index:11;
    left: 0;
    .continue-button{
      position: absolute;
      right: 0;
      background: #8b95a1; height: 100px; width: 200px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .continue-button-active{
      position: absolute;
      right: 0;
      cursor: pointer;
      -webkit-transition: all 0.18s ease-out;
      -moz-transition: all 0.18s ease-out;
      -ms-transition: all 0.18s ease-out;
      -o-transition: all 0.18s ease-out;
      transition: all 0.18s ease-out;

      height: 100px; width: 200px;
      background-color: #00a575;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        height: 120px; width: 240px;

      }
    }

    .bottom-cat-img-container{
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 46px;
      height: 46px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-cat-img{
        width: 22px;
        height: 22px;
      }
    }

    .bottom-cat-title{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      color: #ffffff;
      margin-left: 10px;

    }

    .bottom-centered{
      margin: 0 auto;
      width: 1440px;
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
    .step-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .step-sub-title{
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.46px;
      color: #ffffff;
    }
  }

  .request-warning{

    width: 843px;
    margin-top: 35px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);

    .request-warning-inside{
      display: flex;
      padding: 20px 30px;
    }

    .warning-head{
      font-size: 16px;
      font-weight: normal;

      color: #2d3640;
    }
    .warning-sub-head{
      margin-bottom: 20px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;

      line-height: 1.14;

      color: #2d3640;
    }


    .warning-icon /deep/ {
      width: 35px;
      height: 32px;
      path {
        fill: #f29933;
      }
    }


    .disapproval-row{
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #2d3640;
      .link{
        font-weight: bold;
        color: #fd4056;
      }
    }

  }


  .numberCircle span {

    text-align: center;
    line-height: 30px;
    display: block;
    color: #5e6b79;
    height: 36px;
  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: .5em .75em;
  }

  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: .5em .75em;
    text-decoration: none;
  }

  label {
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    margin-bottom: 20px;
  }

  .error {
    border: 1px solid #d0011b !important;
  }

  /*kind type*/

  .application-sections {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .application-sections-flex1 {
    flex: 1;

    span {
      font-size: 16px;
      color: #8b95a1;
      font-weight: 300;
      padding-right: 10px;
    }
  }

  .application-sections-flex2 {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    textarea {
      width: 90%;
      height: 100px;
      font-size: 18px;
    }
  }

  .step1-categories {
    width: 250px;
    height: 50px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 2px #bfc8d2;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .checkbox-img {
    height: 16px;
    width: 16px;
    margin-left: 15px;
  }

  .categories-label {
    font-size: 16px;
    color: #a1a9a9;
    margin-left: 15px;
    margin-bottom: 0;
    display: unset;
    cursor: pointer;
  }

  /*upload and drag/drop*/
  #request-drag-drop {
    position: relative;
    height: 300px;
    width: 622px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 20px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }

  .file-upload-button {
    padding: 10px;
    border: 1px solid #bfc8d2;
    cursor: pointer;
  }

  .uploaded-files {
    width: calc(100% - 25px);
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .uploaded-file-name {
    color: rgb(230, 50, 98);
  }

  .delete-button {
    cursor: pointer;
  }


.sub-cat-type-kind-container{
  padding-top: 20px;
  .title{
    font-size: 26px;
    font-weight: 500;
    color: #2d3640;
    margin-top: 40px;
  }

  .help-text{
    margin-left: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #5e6b79;
    span{
      font-weight: bold;

    }
  }

}

.meta-options-box{
  display:flex;border-radius: 5px;
  border: solid 1px #bfc8d2;
  margin-top:30px;
  background-color: #fff; min-height: 250px; width: 100%;

  .meta-options-right{
    flex:2;
    background-color: #f3f5f7;
    border-top-left-radius: 4px;
    border-right:solid 1px #bfc8d2;
  }

  .meta-options-right-row-selected{
    background-color: white; border-top-left-radius: 4px;
    width: 101%; height: 60px; display: flex;
    align-items: center; border-bottom:solid 1px #bfc8d2;
  }

  .meta-options-right-row{
    border-top-left-radius: 4px; width: 100%; height: 60px;
    display: flex; align-items: center; border-right:solid 1px #bfc8d2;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }

  .meta-options-right-row-text{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;font-weight: 500;

  }

  .check-icon-container{
    .check-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #00a575;
      }
    }
  }

  .meta-options-left{
    flex:5;
    .option-left-title{
      font-weight: 600;
      font-size: 15px;
      padding-top: 20px;
      padding-left: 30px;
    }

    .meta-options-left-form{
      margin-left: 30px;
      margin-top: 30px;
    }
  }

}

.filter-radio-item{
  display: flex; align-items: center; cursor: pointer;
  margin-bottom: 20px;

  .filter-radio-empty{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px #8b95a1;
  }

  .filter-radio-label{
    font-size: 16px;  color: #5e6b79; margin-left: 8px; line-height: 1.4;

  }

  .filter-radio-label-active{
    font-size: 16px; color: #5e6b79; font-weight: 400; margin-left: 8px; line-height: 1.4
  }

  .filter-radio-full{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 3px #00a575;
  }


}


.filter-checkbox-item{
  cursor: pointer;
  display: flex;
  width: 50%;
  margin-bottom: 24px;


  .filter-checkbox-disabled{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
    opacity: 0.4;
  }

  .filter-checkbox-label-disabled{
    padding-left: 10px;
    font-size: 15px;
    opacity: 0.55;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2D3640;

  }

  .filter-checkbox-empty{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
  }

  .filter-checkbox-full{
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: solid 3px #00a575;

  }

  .filter-checkbox-label{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

  .filter-checkbox-label-active{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

}
</style>
